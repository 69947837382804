const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Casino = () => import(/* webpackChunkName: "casino" */ './views/Casino.vue');
const Games = () => import(/* webpackChunkName: "games" */ './views/Games.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'casino/:type?',
            name: 'casino',
            component: Casino
        },
        {
            path: 'games/:type?',
            name: 'Games',
            component: Games,
            props: true
        }
    ],
}

export default GamesRoutes;