<template>
    <footer class="mobile-footer-menu-hide" v-if="cricketFightRoute()">
        <nav class="mobile-footer-menu">
            <ul>
                <li>
                    <router-link to="/dashboard" class="ui-link" :class="{active : this.$route.name == 'dashboard'}">
                        <img  rel="preload"   src="@/assets/images/trophy.webp" class="icon-sports">
                        {{ translatedLangData('sport','Sports')}}
                    </router-link>
                </li>
                <li>
                    <router-link to="/inplay" class="ui-link" :class="{active : this.$route.name == 'inplay'}">
                        <img  rel="preload"   src="@/assets/images/timer.webp" class="icon-inplay">
                        {{ translatedLangData('inplay','In-Play ')}}
                    </router-link>
                </li>
                <li class="main-nav">
                    <router-link to="/" class="ui-link" :class="{active : this.$route.name == 'Home'}">
                        <img  rel="preload"   src="@/assets/images/home.webp" class="icon-home">
                        {{ translatedLangData('home','Home')}}
                    </router-link>
                </li>
                <li>
                    <a @click="clickOnMultimarket()" href="Javascript:void(0);" class="ui-link" :class="{active : this.$route.name == 'multi-market'}">
                        <img  rel="preload"   src="@/assets/images/pin-white-footer.svg" class="icon-pin">
                        {{ translatedLangData('multi-market','Multi Market')}}
                    </a>
                </li>
                <li>
                    <a @click="clickOnAccount()" class="ui-link" :class="{active : this.$route.name == 'mobile-account'}">
                        <img  rel="preload"   src="@/assets/images/user.webp" class="icon-account">
                        {{ translatedLangData('account','Account')}}
                    </a>
                </li>
                <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop-one">
                </div>
            </ul>
        </nav>
    </footer>
    <template v-if="whatsappIconSHow()">
        <div class="whatsapp-footer-fixed-icn" v-if="siteSettings && siteSettings?.business_type == 2" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal">
            <a href="javascript:void(0);" @click="getParentWhatsappNumber">
                <img  rel="preload"   src="@/assets/images/whatsapp.png" class="whatsapp">
            </a>
        </div>
    </template>

    <a href="javascript:void(0);" class="casino_chip" @click="loadMiniGames()">
        <img src="@/assets/images/casino-chip.png">
    </a>

    <div class="game_lobby_blank" v-if="miniGameUrl"></div>
    <div class="game_lobby_iframe" ref="game_lobby_iframe" v-if="miniGameUrl">
        <span class="lobby-iframe-close-icon" @click="miniGameUrl = null"><img src="@/assets/images/arow-down.webp"></span>
        <iframe :src="miniGameUrl" style="padding: 2px;"></iframe>
    </div>

    <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered information-modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <h3>Customer Support</h3>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="whatsappNumbers && whatsappNumbers.length > 0">
                        <div v-for="(data, index) in whatsappNumbers" :key="index">
                            <a class="whatsapp-now-modal-btn" :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                                <img  rel="preload"   src="@/assets/images/whatsapp.png" class="whatsapp">
                                {{ data.category_name }}
                            </a>
                        </div>
                    </div>
                    <div v-else-if="siteSettings && (siteSettings.social_link?.whatsappno1 || siteSettings.social_link?.whatsappno2)">
                        <div v-for="(number, index) in [siteSettings.social_link?.whatsappno1, siteSettings.social_link?.whatsappno2]" :key="index" class="whatsapp-content-wrapper">
                            <a v-if="number" :key="number" :href="'https://wa.me/' + number" target="_blank" class="whatsapp-now-modal-btn" >
                                <img  rel="preload"   src="@/assets/images/whatsapp.png" class="whatsapp">
                                {{ number }}
                            </a>
                        </div>
                    </div>
                    <div v-else>
                        <p>No WhatsApp numbers available.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import * as apiName from '@/shared/services/urls.js';
import api from '@/shared/services/api';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
export default {
    name: 'MobileFooter',
    inject:['translatedLangData'],
    data() {
        return {
            whatsappNumbers:[],
            miniGameUrl: null
        }
    },
    computed: {
        ...mapGetters([
            'siteSettings',
        ])
    },
    methods: {
        loadMiniGames(){
            if (!this.checkIsLogin()) {
                this.showErrorModalFunc('Please Login For Mini Games');
                return;
            }
            const miniGameData = this.$store.getters.gamesData.sections.find(gm => gm.slug === 'yuvi-games')?.games[0];
            if (!miniGameData) {
                this.showErrorModalFunc('Mini Game Data Not Found.');
                return;
            }
            const headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            };

            api.post(`${apiName.DREAM_CASINO}${miniGameData.game_code}/${miniGameData.sm_id}`, {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.miniGameUrl = response?.data?.data?.url;
                        if (this.miniGameUrl == null || this.miniGameUrl == undefined || this.miniGameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0] || 'An error occurred.');
                }
            });
        },
        clickOnAccount() {
            if (this.checkIsLogin()) {
                this.$router.push({name:'mobile-account'});
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        clickOnMultimarket() {
            if (this.checkIsLogin()) {
                this.$router.push({name:'multi-market'});
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },       
        checkIsLogin(){
            return this.$store.getters.isAuthenticated;
        },
        cricketFightRoute() {
			let routeName = this.$route.name;
			let restrictedRoutes = [
				// 'CricketFight',
				// 'MatchDetails',
				// 'MyContests',
				// 'MyContestBetDetails',
                // 'PlaceBet',
                'matka',
                'matka-details'
			]
			return !restrictedRoutes.includes(routeName);
		},
        whatsappIconSHow() {
            let routePath = this.$route.path;
            return routePath === '/';
        },
        async getParentWhatsappNumber() {
            if (this.checkIsLogin()) {
                this.loading = true;
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }
                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    }
}
</script>

<style>

a.casino_chip {
    position: fixed;
    left: 2%;
    bottom: 15%;
    z-index: 999;
}

a.casino_chip img {
    width: 50px;
    animation: simple-scale 1s 1s linear infinite alternate;
}

@keyframes simple-scale {
    100% {
        transform: scale(1.2);
    }
}

.mini-game figure {
	width: 4.5rem !important;
}

.mini-game-player {
	width: 4.2rem !important;
    position: fixed !important;
    bottom: 0px !important;
	height: 4.2rem !important;
	border-radius: 50%;
	overflow: hidden;
}

.mini-game-player video {
	width: 106%;
	height: 106%;
	margin: -4px -4px 0 0;
}

 .game_lobby_iframe {
    display: flex;
    position: fixed;
    transform: translateZ(0);
    height: 250px;
    z-index: 9999;
    cursor: pointer;
    width: 100%;
    --opacityValue: 0;
    background: #fff;
    bottom: 50px;
    border-radius: 5px;
}

.game_lobby_iframe:after, .game_lobby_iframe:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    background: linear-gradient(45deg,#fff,#c1c1ec,#c3c8fc,#1e1c36,#000,#131329,#d3fcd3,#fcfcd3,#fff);
    background-size: 400%;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;
    animation: steam 20s linear infinite;
    border-radius: 10px 10px 0 0;
}

.game_lobby_iframe iframe {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.lobby-iframe-close-icon{
    position: absolute;
    top: 7px;
    right: 7px;
    background-color: #a47218;
    border-radius: 35px;
    width: 28px;
    text-align: center;
    padding: 0;
    line-height: 26px;
    height: 28px;
}

@keyframes steam {
    0% {
        background-position: 0 0
    }
    50% {
        background-position: 400% 0
    }
    to {
        background-position: 0 0
    }
}

.page-footer .nav .nav-item:first-child {
    flex: 0 0 100px;
}

.page-footer .nav .nav-item {
    flex: 1 1 auto;
}

.page-footer .nav .nav-item.mini-games .nav-link img {
    height: 75px;
    width: 75px;
    margin-top: -45px;
}

.game_lobby_blank {
    height: 250px;
}

</style>